<template>
	<!-- 用户管理 -->
	<div class="rootDiv">
		<div class="retrieve">
			<div class="pv-20 flex flex-jb flex-ac flex-w">
				<div class="mr-10 flex flex-ac">
					<span class="fs-16 flex1 mr-10 w-80">用户名：</span>
					<el-input class="w-150" v-model="retrieveForm.userName" clearable></el-input>
				</div>
				<div class="mr-10 flex flex-ac">
					<span class="fs-16 flex1 mr-10 w-80">手机号：</span>
					<el-input class="w-150" v-model="retrieveForm.phone" clearable></el-input>
				</div>
				<div class="mr-10 flex flex-ac">
					<span class="fs-16 flex1 mr-10">是否领取：</span>
					<el-select class="w-150" v-model="retrieveForm.isRcv" clearable placeholder="请选择价格分区">
						<el-option label="是" value="true"></el-option>
						<el-option label="否" value="false"></el-option>
					</el-select>
				</div>
				<div class="mr-10 flex flex-ac">
					<span class="fs-16 flex1 mr-10">价格分区：</span>
					<el-select class="w-150" v-model="retrieveForm.goodSeriesType" clearable placeholder="请选择价格分区">
						<el-option v-for="item in goodSeriesTypes" :key="item.value" :label="item.key"
							:value="item.value"></el-option>
					</el-select>
				</div>
				<div class="mr-10 flex flex-ac">
					<span class="fs-16 flex1 mr-10">实际等级：</span>
					<el-select class="w-150" v-model="retrieveForm.gradeId" clearable placeholder="请选择价格分区">
						<el-option v-for="item in gradeConsumes" :key="item.id" :label="item.grade" :value="item.id"></el-option>
					</el-select>
				</div>
				<div class="mr-10 flex flex-ac">
					<span class="fs-16 flex1 mr-10 w-80">创建时间：</span>
					<el-date-picker style="width: 200px;" v-model="startEndDate" type="daterange" valueFormat="yyyy-MM-dd"
						range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handPickerChange">
					</el-date-picker>
				</div>
				<div class="mt-10" style="margin-left: auto;padding-right: 10px;">
					<el-button type="primary" @click="submitSearch">查询</el-button>
				</div>
			</div>
		</div>
		<TableHeightFull class="tableDiv pall-30">
			<!-- 表格 -->
			<EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
				v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
				<template v-slot:img="scope">
					<img :src="scope.row.userImg" alt="" class="smallImg" v-if="scope.row.userImg">
				</template>
			</EleTable>
			<!-- 分页 -->
			<PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
		</TableHeightFull>
	</div>
</template>

<script>
	import indexMixin from "@/utils/indexMixin"
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'user',
		mixins: [indexMixin],
		components: {
			"EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
			"TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
			"PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
			"CountDown": (resolve) => require(["@/components/CountDown"], resolve),
		},
		computed: {
			...mapState('menu', {
				'goodSeriesTypes': state => state.dictList ? state.dictList.good_series_type : [], //一番赏分区类型
			})
		},
		data() {
			return {
				retrieveForm: {
					// isBlacklist: '', //是否黑名单 0否1是(默认传0
					// isTest: 0,
					// userName: '', //用户名称,模糊查询
					phone: '', //用户手机号,模糊查询
					gradeId: '',
					isRcv: '',
					goodSeriesType: '',
					startDate: '', //开始时间
					endDate: '', //结束时间
					// userId: '', //用户id
					// sortType: 0, //0按创建时间 1 按消费等级
				},
				tableData: [],
				gradeConsumes: [], //实际等级
				startEndDate: [],
				butLoading: false,
				tabLoading: false,
				columns: [{
						columnType: 'custom',
						label: '头像',
						prop: 'img',
						align: 'left',
						width: '80'
					},
					{
						label: '用户名',
						prop: 'userName',
						align: 'left',
						width: '150',
						'show-overflow-tooltip': true
					},
					{
						label: '购买抽数',
						prop: 'buyNum',
						width: '80',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '保底抽数门槛',
						prop: 'buyNumThreshold',
						align: 'left',
						width: '110',
						'show-overflow-tooltip': true
					},
					{
						label: '消费起点',
						prop: 'startConsume',
						align: 'left',
						width: '100',
						'show-overflow-tooltip': true
					},
					{
						label: '消费结束',
						prop: 'endConsume',
						align: 'left',
						width: '100',
						'show-overflow-tooltip': true
					}, {
						label: '商品名称',
						prop: 'detailName',
						align: 'left',
						width: '200',
						'show-overflow-tooltip': true
					}, {
						label: '价格分区',
						prop: 'goodSeriesType',
						align: 'left',
						width: '80',
						formatData: (e) => {
							let ele = this.goodSeriesTypes.find(ele => ele.value == e)
							return ele ? ele.key : e
						}
					},
					{
						label: '实际等级',
						prop: 'grade',
						width: '80',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '是否领取',
						prop: 'isRcv',
						align: 'left',
						width: '80',
						'show-overflow-tooltip': true,
						formatData: (e) => {
							return e ? '是' : '否'
						}
					},
					{
						label: '领取时间',
						prop: 'rcvDate',
						align: 'left',
						'show-overflow-tooltip': true
					},
					{
						label: '购买时间',
						prop: 'createDate',
						align: 'left',
						'show-overflow-tooltip': true
					}
				]
			}
		},
		mounted() {
			this.getTableData()
		},
		methods: {
			//查询
			submitSearch() {
				this.pageInfo.pageNum = 1
				this.getTableData()
			},
			//时间变化
			handPickerChange(value) {
				if (value && value.length != 0) {
					this.retrieveForm.startDate = value[0]
					this.retrieveForm.endDate = value[1]
				} else {
					this.retrieveForm.startDate = ''
					this.retrieveForm.endDate = ''
				}
			},
			getTableData() {
				this.tabLoading = true
				this.$http.get('/seriesAreaGrade/findUserSeriesBuyNum', {
					params: {
						currentPage: this.pageInfo.pageNum,
						pageSize: this.pageInfo.pageSize,
						...this.retrieveForm
					}
				}).then(({
					data: result
				}) => {
					this.tabLoading = false
					this.tableData = result.data.list
					this.pageInfo.total = result.data.total
					this.findGrade()
				}).catch(err => {
					this.tabLoading = false
				})
			},
			//一番赏商品真实等级
			findGrade() {
				this.$http.get('/grade/findGrade').then(({
					data: result
				}) => {
					this.gradeConsumes = result.data
				})
			},
		},
	}
</script>

<style>
</style>